import { pipe } from 'fp-ts/function'
import { decodeJson, get } from 'lib/request'

import { TAddress } from './address.codecs'

export const getAddress = async (input: {
  latitude: string
  longitude: string
}) => {
  return pipe(
    await get('api/stuff/addresses', {
      query: new URLSearchParams(input),
    }),
    decodeJson(TAddress),
  )
}
