import { TCurrency } from 'api/unions'
import { array, intersection, number, string, type } from 'io-ts'
import { UUID } from 'io-ts-types'

const TPricingItem = type({
  createdAt: string,
  itemId: UUID,
  itemName: string,
  itemValue: number,
})

export const TPricing = type({
  createdAt: string,
  pricingId: UUID,
  pricingName: string,
  pricePerQuarter: number,
  totalPrice: number,
  currency: TCurrency,
  pricingItems: array(TPricingItem),
  taxPercent: number,
})

const TPricingListItem = intersection([
  TPricing,
  type({
    booths: array(
      type({
        boothId: UUID,
        name: string,
      }),
    ),
  }),
])

export const TPricingsList = array(TPricingListItem)
