import { Box, Button, Typography } from '@mui/material'
import { routes } from 'app/routes'
import { LinkPrefetch } from 'components/navigation/InternalLink'

import { ReactComponent as NotFoundIcon } from './not-found.svg'

type Props = {
  label?: string
}

export const NotFound = (props: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={4}
      pt={12}
      textAlign="center"
      color="textPrimary"
    >
      <Box mb={4}>
        <NotFoundIcon width="300px" height="100%" />
      </Box>
      <Typography variant="h5" gutterBottom>
        {props.label ?? 'Page not found'}
      </Typography>
      <Box mt={2}>
        <Button
          component={LinkPrefetch}
          to={routes.index}
          variant="outlined"
          color="primary"
        >
          Go to the home page
        </Button>
      </Box>
    </Box>
  )
}
