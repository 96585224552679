import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { pipe } from 'fp-ts/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { formatDateHyphens } from 'lib/format'
import { decodeJson, get, post, put } from 'lib/request'

import {
  TBookingsArray,
  TUpcomingBooking,
  TUserBookingsArray,
} from './booking.codecs'

export type BookingStatus = 'BOOKED' | 'CANCELED'

export type GetBookingListInput = {
  sort: SortInput
  pagination: PaginationInput
  dateFrom: Date
  dateTo: Date
  status: BookingStatus
}

export const getAllBookings = async ({
  sort,
  pagination,
  dateFrom,
  dateTo,
  status,
}: GetBookingListInput) => {
  const params = createListQueryParams({ pagination, sort })

  params.set('dateFrom', formatDateHyphens(dateFrom))
  params.set('dateTo', formatDateHyphens(dateTo))
  params.set('status', status)

  return pipe(
    await get('api/operation/bookings', {
      query: params,
    }),
    decodeJsonWithTotal(TBookingsArray),
  )
}

export type GetUserBookingsInput = {
  userId: UUID
  search: URLSearchParams
}

export const getUserBookings = async ({
  userId,
  search,
}: GetUserBookingsInput) => {
  return pipe(
    await get('api/operation/users/:userId/bookings', {
      params: {
        userId: userId,
      },
      query: search,
    }),
    decodeJsonWithTotal(TUserBookingsArray),
  )
}

type CheckIfUpcomingBookingsInput = {
  boothIds: Array<UUID>
}

export const checkIfUpcomingBookings = async ({
  boothIds,
}: CheckIfUpcomingBookingsInput) => {
  return pipe(
    await post('api/operation/bookings/booths', {
      body: {
        boothIds,
      },
    }),
    decodeJson(array(TUpcomingBooking)),
  )
}

type CancelUpcomingBookingInput = {
  bookingId: UUID
}

export const cancelUpcomingBooking = async ({
  bookingId,
}: CancelUpcomingBookingInput) => {
  await put('api/operation/bookings/:bookingId/cancel', {
    params: {
      bookingId,
    },
  })
}
