import { useAuthContext } from 'app/auth'
import { pageRoutesAuth, pageRoutesUnauth } from 'app/pages'
import { routes } from 'app/routes'
import { NotFound } from 'components/feedback/NotFound'
import { RuntimeErrorDialog } from 'components/feedback/RuntimeErrorDialog'
import { AppLayout } from 'components/layout/AppLayout'
import { PodUnpausingIndicator } from 'components/layout/PodUnpausingIndicator'
import { createRoutes } from 'lib/lazy-routing'
import { Logout } from 'pages/logout'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, Switch } from 'react-router-dom'

export const App = () => {
  const { auth } = useAuthContext()

  if (auth.type === 'initial') {
    return null
  }

  if (auth.type === 'unauthenticated') {
    return (
      <Suspense fallback={null}>
        <Switch>
          {createRoutes(pageRoutesUnauth)}
          <Redirect to={routes.login} />
        </Switch>
      </Suspense>
    )
  }

  return (
    <AppLayout>
      <PodUnpausingIndicator />
      <ErrorBoundary FallbackComponent={RuntimeErrorDialog}>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={routes.logout} component={Logout} />
            {createRoutes(pageRoutesAuth)}
            <Redirect exact from={routes.login} to={routes.index} />
            <Redirect exact from={routes.index} to={routes.pods} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </AppLayout>
  )
}
