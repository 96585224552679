import { array, string, type } from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TFeature = type({
  createdAt: DateFromISOString,
  featureId: UUID,
  featureName: string,
})

export const TFeaturesList = array(TFeature)
