import { pipe } from 'fp-ts/function'
import { decodeJson, post } from 'lib/request'

import { TFileUploadResult } from './file.codecs'

type UploadFileInput = {
  isThumbnail: boolean
  file: File
}

export const uploadFile = async ({ isThumbnail, file }: UploadFileInput) => {
  return pipe(
    await post('api/stuff/files', {
      type: 'file',
      body: {
        isThumbnail,
        file,
      },
    }),
    decodeJson(TFileUploadResult),
  )
}
