import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconCheckMark = ({
  search,
  ...props
}: SvgIconProps & { search?: boolean }) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6088 8.35876L10.4131 18.5544H7.25812L3 14.2963L6.35876 10.9375L8.83563 13.4144L17.25 5L20.6088 8.35876Z"
        fill={props.fill || theme.palette.primary.main}
      />
    </SvgIcon>
  )
}
