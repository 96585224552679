import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconCopy = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4552 6.54375H9.93633V8.32324H14.0626H15.6376V9.89824V14.1022H17.4552V6.54375ZM8.36133 6.54375V8.32324H6.54375H4.96875V9.89824V17.4567V19.0317H6.54375H14.0626H15.6376V17.4567V15.6772H17.4552H19.0302V14.1022V6.54375V4.96875H17.4552H9.93633H8.36133V6.54375ZM8.36133 9.90088H9.93633V9.89824H14.0626V9.90088H14.2116V17.5734H6.53906V9.90088H6.54375V9.89824H8.36133V9.90088Z"
        fill="#4A4A4A"
      />
    </SvgIcon>
  )
}
