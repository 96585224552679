import { forwardRef, useEffect } from 'react'
import { Link, LinkProps, matchPath } from 'react-router-dom'

import { LazyRoute } from './lazy-route'

export const createLinkPreload = (routes: Array<LazyRoute>) => {
  const findComponentForRoute = (path: string, routes: Array<LazyRoute>) => {
    const matchingRoute = routes.find(route =>
      matchPath(path, {
        path: route.path,
        exact: route.exact,
      }),
    )
    return matchingRoute ? matchingRoute.component : null
  }

  const preloadRouteComponent = (path: string) => {
    const component = findComponentForRoute(path, routes)
    if (component && component.preload) {
      component.preload()
    }
  }

  const LinkPreload = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ to, ...rest }, ref) => {
      /**
       * Right now, a component is prefetched when LinkPreload is mounted.
       * The algorithm that determines if a component should be prefetched can be improved.
       * See https://github.com/GoogleChromeLabs/quicklink for inspiration.
       * - Detects links within the viewport.
       * - Waits until the browser is idle.
       * - Checks if the user isn't on a slow connection.
       */
      useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
          preloadRouteComponent(to)
        }
      }, [to])

      return <Link ref={ref} to={to} {...rest} />
    },
  )

  return LinkPreload
}
