import { TUserRole } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
} from 'io-ts'
import { UUID } from 'io-ts-types'

export const TUserResponse = intersection([
  type({
    userId: UUID,
    active: boolean,
  }),
  partial({
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    address: string,
    companyName: string,
  }),
])

export const TMyProfile = intersection([
  TUserResponse,
  type({
    role: TUserRole,
    scopes: array(string),
  }),
])

export const TRefreshResult = type({
  accessToken: string,
  expires: number,
  userDetails: TMyProfile,
})

export const TLoginResult = type({
  accessToken: string,
  clientId: string,
  expires: number,
  userDetails: TMyProfile,
})
