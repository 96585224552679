import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { AccountType } from 'api/unions'
import { pipe } from 'fp-ts/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, get, patch, post } from 'lib/request'

import {
  TSubscriber,
  TUserBookingsAndRental,
  TUserResponsesList,
} from './user.codecs'

type ChangeUserStatusInput = {
  userIds: Array<UUID>
  status: 'ACTIVE' | 'DEACTIVATED'
  reason: string
  accountTypes: Array<AccountType>
}

export const changeUserStatus = async (input: ChangeUserStatusInput) => {
  return await patch('api/operation/users', {
    body: input,
  })
}

export type GetBusinessMembersWithUpcomingBookingsAndRentalInput = {
  businessId: UUID
}

export const getMembersWithUpcomingBookingsAndRental = async ({
  businessId,
}: GetBusinessMembersWithUpcomingBookingsAndRentalInput) => {
  return pipe(
    await get('api/operation/:businessId/users', {
      params: { businessId },
    }),
    decodeJson(TUserResponsesList),
  )
}

type CheckUserUpcomingBookingsAndRentalInput = {
  userId: UUID
  body: {
    accountTypes: Array<AccountType>
  }
}

export const checkUserUpcomingBookingsAndRental = async ({
  userId,
  body,
}: CheckUserUpcomingBookingsAndRentalInput) => {
  return pipe(
    await post('api/operation/users/:userId/info', {
      params: {
        userId,
      },
      body,
    }),
    decodeJson(TUserBookingsAndRental),
  )
}

export type GetSubscriberListInput = {
  pagination?: PaginationInput
  sort: SortInput
  accountType: AccountType
}

export const getSubscriberList = async ({
  pagination,
  sort,
  accountType,
}: GetSubscriberListInput) => {
  const query = createListQueryParams({ pagination, sort })
  query.append('accountType', accountType)

  return pipe(
    await get('api/operation/users/subscriptions', {
      query,
    }),
    decodeJsonWithTotal(array(TSubscriber)),
  )
}
