import { TUserResponse } from 'api/auth'
import { TCurrency } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  strict,
  string,
  type,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TBooking = intersection([
  strict({
    bookingId: UUID,
    dateFrom: DateFromISOString,
    dateTo: DateFromISOString,
    rentalDetails: strict({
      rentalId: UUID,
      boothResponse: intersection([
        strict({
          boothId: UUID,
          name: string,
        }),
        partial({
          location: strict({
            locationId: string,
            locationName: string,
          }),
          city: string,
          street: string,
          number: string,
        }),
      ]),
      userResponse: TUserResponse,
    }),
  }),
  partial({
    removedAt: DateFromISOString,
  }),
])

export const TBookingsArray = array(TBooking)

export const TUserBooking = intersection([
  strict({
    bookingId: UUID,
    dateFrom: DateFromISOString,
    dateTo: DateFromISOString,
    timeZone: string,
    rentalDetails: type({
      totalPrice: number,
      currency: TCurrency,
      boothResponse: intersection([
        strict({
          name: string,
        }),
        partial({
          location: type({
            locationName: string,
          }),
        }),
      ]),
    }),
    userResponse: TUserResponse,
  }),
  partial({
    withdrawAmount: number,
    removedAt: DateFromISOString,
  }),
])

export const TUserBookingsArray = array(TUserBooking)

export const TUpcomingBooking = type({
  boothId: string,
  booked: boolean,
})
