import { styled } from '@mui/material'
import { SnackbarProvider as Provider } from 'notistack'

export const SnackbarProvider = styled(Provider)(({ theme }) => ({
  '&.SnackbarContent-root': {
    maxWidth: '324px',
  },

  '&.SnackbarItem-variantSuccess': {
    background: theme.palette.success.main,
  },

  '&.SnackbarItem-variantInfo': {
    background: theme.palette.info.main,
  },

  '&.SnackbarItem-variantError': {
    background: theme.palette.error.main,
  },
}))
