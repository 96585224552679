import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material'
import { routes } from 'app/routes'
import {
  IconBookings,
  IconBusinessAccounts,
  IconDiscountCodes,
  IconFeatures,
  IconFeedbacks,
  IconLocations,
  IconMyBooths,
  IconPricings,
  IconRentals,
  IconUsers,
} from 'assets/icons'
import logo from 'assets/images/logo.svg'
import { InternalLink, LinkPrefetch } from 'components/navigation/InternalLink'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'

const menuItems = [
  { route: routes.pods, name: 'Pods', Icon: IconMyBooths },
  { route: routes.locations, name: 'Locations', Icon: IconLocations },
  { route: routes.pricing, name: 'Pricings', Icon: IconPricings },
  { route: routes.features, name: 'Features', Icon: IconFeatures },
  { route: routes.users, name: 'Users', Icon: IconUsers },
  {
    route: routes.businessAccounts,
    name: 'Business accounts',
    Icon: IconBusinessAccounts,
  },
  {
    route: routes.rentals,
    name: 'Rentals',
    Icon: IconRentals,
  },
  { route: routes.bookings, name: 'Bookings', Icon: IconBookings },
  { route: routes.feedbacks, name: 'Feedbacks', Icon: IconFeedbacks },
  {
    route: routes.discountCodes,
    name: 'Discount codes',
    Icon: IconDiscountCodes,
  },
  {
    route: routes.subscriptions,
    name: 'Subscriptions',
    Icon: IconPricings,
  },
  {
    route: routes.subscribers,
    name: 'Subscribers',
    Icon: IconUsers,
  },
]

type Props = {
  closeDrawer?: () => void
}

export const Menu = ({ closeDrawer }: Props) => {
  const theme = useTheme()
  const { pathname: currentPathName } = useLocation()

  const checkPathIsActive = (pathname: string) => {
    return Boolean(
      matchPath(pathname, {
        path: currentPathName,
      }),
    )
  }

  return (
    <Box pt={3} pb={7} height="100%">
      <InternalLink to={routes.index} sx={{ pl: 3 }}>
        <img src={logo} alt="TaskPod" width="140px" />
      </InternalLink>

      <Stack justifyContent="space-between" height="100%" pt={4}>
        <List
          sx={{
            '& .MuiListItem-root.Mui-selected': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
              fontWeight: 600,

              '& .MuiListItemIcon-root': {
                color: theme.palette.primary.main,
              },

              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: '2px',
                background: theme.palette.primary.main,
              },
            },
          }}
        >
          {menuItems.map(({ route, name, Icon }) => (
            <ListItem
              key={route}
              button
              selected={checkPathIsActive(route)}
              component={LinkPrefetch}
              to={route}
              onClick={closeDrawer}
            >
              <ListItemIcon sx={{ mr: 1.5, minWidth: '24px' }}>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>

        <List>
          <ListItem
            button
            to={routes.logout}
            component={LinkPrefetch}
            onClick={closeDrawer}
          >
            <ListItemIcon sx={{ mr: 1.5, minWidth: '24px' }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Stack>
    </Box>
  )
}
