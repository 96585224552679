import 'styles/fonts.css'
import 'styles/index.css'

import { CssBaseline } from '@mui/material'
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AuthProvider } from 'app/auth'
import { env } from 'app/env'
import { reportWebVitals } from 'app/report-web-vitals'
import { theme } from 'app/theme'
import { SnackbarProvider } from 'components/feedback/Snackbar'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import { App } from './app'

const stripePromise = loadStripe(env.REACT_APP_STRIPE_SECRET)

// @TODO: when @mui/styles will not be needed:
// - remove DefaultTheme declaration
// - import ThemeProvider from @mui/material
// - remove StyledEngineProvider

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Palette {
    dim: Palette['primary']
  }

  interface PaletteOptions {
    dim: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dim: true
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

async function main() {
  if (
    process.env.NODE_ENV === 'development' &&
    (process.env.REACT_APP_MSW === 'on' ||
      process.env.REACT_APP_MSW === 'quiet')
  ) {
    const { worker } = await import('./mocks/browser')
    await worker.start({ quiet: process.env.REACT_APP_MSW === 'quiet' })
  }

  ReactDOM.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ErrorBoundary fallbackRender={() => <div>Runtime error.</div>}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              preventDuplicate
            >
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <BrowserRouter>
                  <AuthProvider>
                    <Elements stripe={stripePromise}>
                      <App />
                    </Elements>
                  </AuthProvider>
                </BrowserRouter>
              </QueryClientProvider>
            </SnackbarProvider>
          </ErrorBoundary>
        </StyledEngineProvider>
      </ThemeProvider>
    </StrictMode>,
    document.getElementById('root'),
  )
}

main()

if (env.REACT_APP_REPORT_WEB_VITALS === 'on') {
  reportWebVitals(console.log)
}
