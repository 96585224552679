import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
} from 'api/list'
import { DayOfWeek, PodWorkingType } from 'api/unions'
import { pipe } from 'fp-ts/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post, put } from 'lib/request'

import { TBoothItemDetails, TBoothsWithSchedule } from './booth.codecs'

export type GetBoothInput = {
  boothId: UUID
}

export const getBoothDetails = async (input: GetBoothInput) => {
  return pipe(
    await get('api/stuff/booths/:boothId', {
      params: {
        boothId: input.boothId,
      },
    }),
    decodeJson(TBoothItemDetails),
  )
}

export type GetBoothListInput = {
  pagination?: PaginationInput
  body: Record<string, any>
}

export const getBoothsList = async ({
  pagination,
  body,
}: GetBoothListInput) => {
  const query = pagination ? createListQueryParams({ pagination }) : undefined

  return pipe(
    await post('api/stuff/booths/list', {
      query,
      body,
    }),
    decodeJsonWithTotal(array(TBoothItemDetails)),
  )
}

export const deleteBooth = async (input: { boothId: UUID }) => {
  return await del('api/stuff/booths/:boothId', {
    params: {
      boothId: input.boothId,
    },
  })
}

export const changeBoothStatus = async (input: {
  boothId: UUID
  status: 'ACTIVE' | 'INACTIVE'
}) => {
  return await put('api/stuff/booths/:boothId/status', {
    params: {
      boothId: input.boothId,
    },
    query: new URLSearchParams({
      value: input.status,
    }),
  })
}

export const unlockBooth = async (input: { boothId: UUID }) => {
  return await post('api/stuff/booths/:boothId/unlock', {
    params: {
      boothId: input.boothId,
    },
  })
}

export const changeMultipleBoothsStatus = async (input: {
  status: 'ACTIVE' | 'INACTIVE'
  boothIds: Array<UUID>
}) => {
  return await patch('api/stuff/booths/status', {
    query: new URLSearchParams({ value: input.status }),
    body: {
      boothIds: input.boothIds,
    },
  })
}

// @TODO: poor typing
export const createBooth = async (input: { values: any }) => {
  return pipe(
    await post('api/stuff/booths', {
      body: input.values,
    }),
    decodeJson(TBoothItemDetails),
  )
}

export const publishBooth = async (input: { boothId: UUID }) => {
  return await put('api/stuff/booths/:boothId/publish', {
    params: {
      boothId: input.boothId,
    },
  })
}

// @TODO: poor typing
export const updateBooth = async (input: { values: any; boothId: UUID }) => {
  return await put('api/stuff/booths/:boothId', {
    body: input.values,
    params: {
      boothId: input.boothId,
    },
  })
}

export type RequestDaysOff = Array<{
  date: Date | null
  recurring: boolean
}>

type ChangePodsScheduleInput = {
  boothIds: Array<UUID>
  daysOff?: RequestDaysOff
  workingHours?: Array<{
    dayOfWeek: DayOfWeek
    type: PodWorkingType
    from?: string
    to?: string
  }>
}

export const changePodsSchedule = async ({
  boothIds,
  daysOff,
  workingHours,
}: ChangePodsScheduleInput) => {
  return await patch('api/stuff/booths/schedules', {
    body: {
      boothIds,
      scheduleRequest: {
        daysOff,
        workingHours,
      },
    },
  })
}

export type GetBoothsWithScheduleInput = {
  boothIds: Array<UUID>
}

export const getBoothsWithSchedule = async ({
  boothIds,
}: GetBoothsWithScheduleInput) => {
  return pipe(
    await post('api/stuff/booths/schedule/verification', {
      body: {
        boothIds,
      },
    }),
    decodeJson(TBoothsWithSchedule),
  )
}
