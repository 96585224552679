import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { BusinessStatus } from 'api/unions'
import { pipe } from 'fp-ts/function'
import { UUID } from 'io-ts-types'
import { decodeJson, get, patch, post, put } from 'lib/request'

import { TBusinessAccount, TBusinessAccountsList } from './business.codecs'

export type GetBusinessAccountInput = {
  businessId: UUID
}

export const getBusinessAccount = async ({
  businessId,
}: GetBusinessAccountInput) => {
  return pipe(
    await get('api/auth/business/:businessId', {
      params: { businessId },
    }),
    decodeJson(TBusinessAccount),
  )
}

type UpdateBusinessAccountInput = {
  businessId: UUID
  body: Record<string, any>
}

export const updateBusinessAccount = async ({
  businessId,
  body,
}: UpdateBusinessAccountInput) => {
  return await put('api/auth/business/:businessId', {
    params: { businessId },
    body,
  })
}

export type GetBusinessAccountsListInput = {
  sort: SortInput
  search?: string
  pagination: PaginationInput
}

export const getBusinessAccountsList = async ({
  sort,
  search,
  pagination,
}: GetBusinessAccountsListInput) => {
  const query = createListQueryParams({ sort, pagination })

  if (search !== undefined) {
    query.set('search', search)
  }

  return pipe(
    await get('api/auth/business', { query }),
    decodeJsonWithTotal(TBusinessAccountsList),
  )
}

type CreateBusinessAccountInput = {
  body: Record<string, any>
}

export const createBusinessAccount = async ({
  body,
}: CreateBusinessAccountInput) => {
  return await post('api/auth/business', {
    body,
  })
}

type CreateStaffMemberInput = {
  businessId: UUID
  body: Record<string, any>
}

export const createStaffMember = async ({
  businessId,
  body,
}: CreateStaffMemberInput) => {
  return await post('api/auth/business/:businessId/users', {
    params: { businessId },
    body,
  })
}

type ChangeBusinessStatusInput = {
  businessId: UUID
  status: BusinessStatus
}

export const changeBusinessStatus = async ({
  businessId,
  status,
}: ChangeBusinessStatusInput) => {
  return await patch('api/auth/business/:businessId/statuses', {
    params: { businessId },
    query: new URLSearchParams({
      status,
    }),
  })
}

type ResendInviteToBusinessInput = {
  businessId: UUID
}

export const resendInviteToBusiness = async ({
  businessId,
}: ResendInviteToBusinessInput) => {
  return await post('api/auth/business/:businessId/users/invite', {
    params: { businessId },
  })
}
