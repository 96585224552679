import MenuIcon from '@mui/icons-material/Menu'
import { Box, Hidden, IconButton } from '@mui/material'
import { AppDrawer } from 'components/layout/AppLayout/AppDrawer'
import { useBoolean } from 'hooks/use-boolean'
import { ReactNode } from 'react'

import { AppContent } from './AppContent'

type Props = {
  children: ReactNode
}

export const AppLayout = (props: Props) => {
  const drawerOpen = useBoolean()

  return (
    <Box display="flex" width="100%" maxWidth="100vw" justifyContent="flex-end">
      <IconButton
        edge="start"
        onClick={drawerOpen.setTrue}
        sx={{ position: 'absolute', top: '16px', left: '32px' }}
      >
        <MenuIcon
          sx={{
            color: 'text.secondary',
          }}
        />
      </IconButton>
      <AppDrawer
        open={drawerOpen.isTrue}
        onOpen={drawerOpen.setTrue}
        onClose={drawerOpen.setFalse}
      />
      <Box display="flex" flexDirection="column" width={1} height="100vh">
        <AppContent>{props.children}</AppContent>
      </Box>
    </Box>
  )
}
