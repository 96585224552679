import {
  T3dsPayment,
  TSubscription,
} from 'api/operation/subscription/subscription.codec'
import { AccountType } from 'api/unions'
import { pipe } from 'fp-ts/lib/function'
import { array } from 'io-ts'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, post, put } from 'lib/request'

export type GetSubscriptionListInput = {
  planType: AccountType
}

export const getSubscriptionList = async ({
  planType,
}: GetSubscriptionListInput) => {
  return pipe(
    await get('api/operation/subscriptions', {
      query: new URLSearchParams({ planType }),
    }),
    decodeJson(array(TSubscription)),
  )
}

export type GetSubscriptionInput = {
  subscriptionId: UUID
}

export const getSubscription = async ({
  subscriptionId,
}: GetSubscriptionInput) => {
  return pipe(
    await get('api/operation/subscriptions/:subscriptionId', {
      params: {
        subscriptionId,
      },
    }),
    decodeJson(TSubscription),
  )
}

type UpdateSubscriptionInput = {
  subscriptionId: UUID
  body: {
    pricePerMonth: number
    availableMinutes: number
    pricePerQuarter?: number
  }
}

export const updateSubscription = async ({
  subscriptionId,
  body,
}: UpdateSubscriptionInput) => {
  return pipe(
    await put('api/operation/subscriptions/:subscriptionId', {
      params: {
        subscriptionId,
      },
      body,
    }),
  )
}

type UpdateSubscriptionForBusinessInput = {
  businessId: UUID
  body: {
    subscriptionId: string
    subscriptionMinutes?: number
  }
}

export const updateSubscriptionForBusiness = async ({
  businessId,
  body,
}: UpdateSubscriptionForBusinessInput) => {
  return pipe(
    await put('api/operation/subscriptions/businesses/:businessId', {
      params: {
        businessId,
      },
      body,
    }),
    decodeJson(T3dsPayment),
  )
}

type UpdateEnterpriseSubscriptionInput = {
  body: {
    businessId: UUID
    subscriptionMinutes: number
  }
}

export const updateEnterpriseSubscription = async ({
  body,
}: UpdateEnterpriseSubscriptionInput) => {
  return pipe(
    await put('api/operation/subscriptions/businesses/enterprise', {
      body,
    }),
  )
}

type CancelSubscriptionInput = {
  body: {
    accountId: UUID
    subscriptionId: UUID
  }
}

export const cancelSubscription = async ({ body }: CancelSubscriptionInput) => {
  return pipe(
    await del('api/operation/subscriptions/cancellation', {
      body,
    }),
  )
}

type ChallengeSubscriptionInput = {
  payload: string
}

export const challengeSubscription = async (
  input: ChallengeSubscriptionInput,
) => {
  return await post('api/operation/subscriptions/me/challenge', {
    body: input,
  })
}
