import { format } from 'date-fns'

export const formatDate = (date: Date) => format(date, 'dd.MM.y')
export const formatDateSlashes = (date: Date) => format(date, 'dd/MM/yyyy')
export const formatDateHyphens = (date: Date) => format(date, 'yyyy-MM-dd')

export const formatTime = (date: Date) => format(date, 'p')

const MINUTES_IN_WEEK = 10080
const MINUTES_IN_DAY = 1440
const MINUTES_IN_HOUR = 60

export const getReadableTime = (minutes: number) => {
  const weeks =
    minutes >= MINUTES_IN_WEEK ? Math.floor(minutes / MINUTES_IN_WEEK) : 0
  const weeksInMinutes = weeks * MINUTES_IN_WEEK
  const renderWeeks = weeks > 0 ? `${weeks}w` : ''

  const days =
    minutes >= MINUTES_IN_DAY
      ? Math.floor((minutes - weeksInMinutes) / MINUTES_IN_DAY)
      : 0
  const daysInMinutes = days * MINUTES_IN_DAY
  const renderDays = days > 0 ? `${days}d` : ''

  const hours =
    minutes >= MINUTES_IN_HOUR
      ? Math.floor((minutes - weeksInMinutes - daysInMinutes) / MINUTES_IN_HOUR)
      : 0
  const renderHours = hours > 0 ? `${hours}h` : ''

  const mins =
    hours > 0
      ? minutes % MINUTES_IN_HOUR
      : minutes < MINUTES_IN_HOUR
      ? minutes
      : 0
  const renderMinutes = mins > 0 ? `${mins}m` : ''

  return `${renderWeeks} ${renderDays} ${renderHours} ${renderMinutes}`.trim()
}

export const getMinutesFromReadableTime = (input: string) => {
  const getValueByTimeUnit = (unit: 'w' | 'd' | 'h' | 'm') => {
    const regEx = new RegExp(`(\\d+)(?=\\s*${unit})`, 'g')

    const values = input.match(regEx)
    return values === null ? 0 : Number(values[0])
  }

  const weeksInMinutes = getValueByTimeUnit('w') * MINUTES_IN_WEEK
  const daysInMinutes = getValueByTimeUnit('d') * MINUTES_IN_DAY
  const hoursInMinutes = getValueByTimeUnit('h') * MINUTES_IN_HOUR
  const minutes = getValueByTimeUnit('m')

  return weeksInMinutes + daysInMinutes + hoursInMinutes + minutes
}
