import { createLazyRoute, LazyRoute } from 'lib/lazy-routing'

import { routes } from './routes'

const lazyRoute = createLazyRoute({
  modifyTitle: title => `${title} · TaskPod`,
})

export const pageRoutesAuth: Array<LazyRoute> = [
  lazyRoute({
    title: 'Features',
    path: routes.features,
    factory: () => import(`pages/feature`),
  }),
  lazyRoute({
    title: 'Add a new feature',
    path: routes.newFeature,
    factory: () => import(`pages/feature/new`),
  }),
  lazyRoute({
    title: 'Locations',
    path: routes.locations,
    factory: () => import(`pages/locations`),
  }),
  lazyRoute({
    title: 'Add a new location',
    path: routes.newLocation,
    factory: () => import(`pages/locations/new`),
  }),
  lazyRoute({
    title: 'Edit location',
    path: routes.editLocation,
    factory: () => import('pages/locations/[locationId]'),
  }),
  lazyRoute({
    title: 'Pricings',
    path: routes.pricing,
    factory: () => import(`pages/pricings`),
  }),
  lazyRoute({
    title: 'Add a new pricing',
    path: routes.newPricing,
    factory: () => import(`pages/pricings/new`),
  }),
  lazyRoute({
    title: 'Edit pricing',
    path: routes.editPricing,
    factory: () => import(`pages/pricings/[pricingId]`),
  }),
  lazyRoute({
    title: 'Pods',
    path: routes.pods,
    factory: () => import(`pages/pods`),
  }),
  lazyRoute({
    title: 'Add a new pod',
    path: [routes.newPod, routes.newPodCopy],
    factory: () => import(`pages/pods/new`),
  }),
  lazyRoute({
    title: 'Edit pod',
    path: routes.editPod,
    factory: () => import(`pages/pods/edit`),
  }),
  lazyRoute({
    title: 'Assign pod schedule',
    path: routes.assignPodWorkingHours,
    factory: () => import(`pages/pods/assign-working-hours`),
  }),
  lazyRoute({
    title: 'Assign pod days off',
    path: routes.assignPodDaysOff,
    factory: () => import(`pages/pods/assign-days-off`),
  }),
  lazyRoute({
    title: 'Pod details',
    path: routes.podDetails,
    factory: () => import(`pages/pods/[podId]`),
  }),
  lazyRoute({
    title: 'Bookings',
    path: routes.bookings,
    factory: () => import('pages/bookings'),
  }),
  lazyRoute({
    title: 'Users',
    path: routes.users,
    factory: () => import('pages/users'),
  }),
  lazyRoute({
    title: 'Cancelled bookings',
    path: routes.canceledBookings,
    factory: () => import('pages/users/CanceledBookings'),
  }),
  lazyRoute({
    title: 'Upcoming bookings',
    path: routes.upcomingBookings,
    factory: () => import('pages/users/UpcomingBookings'),
  }),
  lazyRoute({
    title: 'Past bookings',
    path: routes.pastBookings,
    factory: () => import('pages/users/PastBookings'),
  }),
  lazyRoute({
    title: 'Rentals',
    path: routes.rentals,
    factory: () => import('pages/rentals'),
  }),
  lazyRoute({
    title: 'Usages',
    path: routes.usages,
    factory: () => import('pages/usages'),
  }),
  lazyRoute({
    title: 'Feedbacks',
    path: routes.feedbacks,
    factory: () => import(`pages/feedbacks`),
  }),
  lazyRoute({
    title: 'Discount Codes',
    path: routes.discountCodes,
    factory: () => import(`pages/discount-codes`),
  }),
  lazyRoute({
    title: 'Add a new discount code',
    path: routes.newDiscount,
    factory: () => import(`pages/discount-codes/new`),
  }),
  lazyRoute({
    title: 'Edit discount code',
    path: routes.editDiscount,
    factory: () => import(`pages/discount-codes/edit`),
  }),
  lazyRoute({
    title: 'Business accounts',
    path: routes.businessAccounts,
    factory: () => import(`pages/business-accounts`),
  }),
  lazyRoute({
    title: 'Add a new business account',
    path: routes.newBusinessAccount,
    factory: () => import(`pages/business-accounts/new`),
  }),
  lazyRoute({
    title: 'Edit business account',
    path: routes.editBusinessAccount,
    factory: () => import(`pages/business-accounts/edit`),
  }),
  lazyRoute({
    title: 'Add a new staff member',
    path: routes.newStaffMember,
    factory: () => import(`pages/business-accounts/newStaffMember`),
  }),
  lazyRoute({
    title: 'Add a business admin',
    path: routes.newBusinessAdmin,
    factory: () => import(`pages/business-accounts/newBusinessAdmin`),
  }),
  lazyRoute({
    title: 'Subscriptions',
    path: routes.subscriptions,
    factory: () => import(`pages/subscriptions`),
  }),
  lazyRoute({
    title: 'Edit subscription',
    path: routes.editSubscription,
    factory: () => import(`pages/subscriptions/edit`),
  }),

  lazyRoute({
    title: 'Subscribers',
    path: routes.subscribers,
    factory: () => import(`pages/subscribers`),
  }),
]

export const pageRoutesUnauth: Array<LazyRoute> = [
  lazyRoute({
    title: 'Sign in',
    path: routes.login,
    factory: () => import(`pages/login`),
  }),
  lazyRoute({
    title: 'Forgot password',
    path: routes.forgotPassword,
    factory: () => import(`pages/password/reset`),
  }),
  lazyRoute({
    title: 'Confirm password reset',
    path: routes.recoveryPassword,
    factory: () => import(`pages/password/recovery`),
  }),
  lazyRoute({
    title: 'Email confirmation',
    path: routes.emailConfirmation,
    factory: () => import(`pages/email`),
  }),
  lazyRoute({
    title: 'Email verification resend',
    path: routes.emailResend,
    factory: () => import(`pages/email/resend`),
  }),
]

export const allPageRoutes = [...pageRoutesAuth, ...pageRoutesUnauth]
