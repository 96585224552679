import { createSvgIcon } from '@mui/material'

export const IconLogout = createSvgIcon(
  <g>
    <path d="M0 0V18H7.875V15.75H2.25V2.25H7.875V0H0Z" fill="currentColor" />
    <path
      d="M10.125 4.5V7.875L4.5 7.875V10.125H10.125V13.5H11.25L14.625 10.125V7.875L11.25 4.5H10.125Z"
      fill="currentColor"
    />
  </g>,
  'IconLogout',
)
