import { pipe } from 'fp-ts/lib/function'
import { decodeJson, del, post } from 'lib/request'

import { TLoginResult, TRefreshResult } from './token.codecs'

export const refreshToken = async (input: { clientId: string }) => {
  return pipe(
    await post('api/auth/tokens/refresh', {
      body: {
        clientId: input.clientId,
      },
    }),
    decodeJson(TRefreshResult),
  )
}

export const issueToken = async (input: {
  clientId: string
  email: string
  password: string
}) => {
  return pipe(
    await post('api/auth/tokens/credentials', { body: input }),
    decodeJson(TLoginResult),
  )
}

export const logout = async () => {
  return await del('api/auth/tokens/me', {})
}
