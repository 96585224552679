import {
  TBoothStatus,
  TDayOfWeek,
  TImageSize,
  TPodWorkingType,
} from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  record,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, optionFromNullable, UUID } from 'io-ts-types'

import { TFeature } from '../feature'
import { TLocationBooth } from '../location'
import { TPricing } from '../pricing'

export const TImageVariation = type({
  createdAt: DateFromISOString,
  fileKey: UUID,
  fileUrl: string,
  imageSize: TImageSize,
  originalFileName: string,
})

export const TImageVariationShort = type({
  fileKey: UUID,
  fileUrl: string,
  originalName: string,
})

export type ImageVariation = TypeOf<typeof TImageVariation>

export type ImageVariationShort = TypeOf<typeof TImageVariationShort>

export const TImageVariationsArray = array(TImageVariation)
export type ImageVariationsArray = TypeOf<typeof TImageVariationsArray>

export const TImageVariations = array(record(UUID, TImageVariationsArray))

export const TBoothListItemPart = type({
  boothId: UUID,
  name: string,
})

export const TBooth = intersection([TBoothListItemPart, type({})])

const TWorkingHoursItem = intersection([
  type({
    dayOfWeek: TDayOfWeek,

    type: TPodWorkingType,
  }),
  partial({
    from: string,
    to: string,
  }),
])

export type WorkingHoursItem = TypeOf<typeof TWorkingHoursItem>

export const TBoothItemDetails = intersection([
  TBooth,
  type({
    boothId: UUID,
    name: string,
    createdAt: DateFromISOString,
    imageVariations: TImageVariations,
    latitude: optionFromNullable(number),
    location: optionFromNullable(TLocationBooth),
    longitude: optionFromNullable(number),
    persons: optionFromNullable(number),
    pricing: optionFromNullable(TPricing),
    status: TBoothStatus,
    city: optionFromNullable(string),
    street: optionFromNullable(string),
    floor: optionFromNullable(number),
    lockNumber: optionFromNullable(string),
    features: array(TFeature),
    number: optionFromNullable(string),
    wifiNetwork: optionFromNullable(string),
    wifiPassword: optionFromNullable(string),
  }),
  partial({
    previewImage: TImageVariationShort,
    defaultPassword: string,
    description: string,
    addressDescription: string,
    scheduleResponse: partial({
      daysOff: array(
        type({
          date: DateFromISOString,
          recurring: boolean,
        }),
      ),
      workingHours: array(TWorkingHoursItem),
    }),
    sensorId: number,
  }),
])

export type BoothItemDetails = TypeOf<typeof TBoothItemDetails>

export const TBoothsWithSchedule = array(
  partial({
    boothName: string,
  }),
)
