import { TUserResponse } from 'api/auth'
import { TAccountType, TCurrency, TRentalStatus } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  strict,
  string,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TRental = intersection([
  strict({
    rentalId: UUID,
    pricePerQuarter: number,
    currency: TCurrency,
    createdAt: DateFromISOString,
    rentalStatus: TRentalStatus,
    booked: boolean,
    paid: boolean,
    timeZone: string,
    boothResponse: intersection([
      strict({
        boothId: UUID,
        name: string,
      }),
      partial({
        location: strict({
          locationId: string,
          locationName: string,
        }),
        city: string,
        street: string,
        number: string,
      }),
    ]),
    startedAt: DateFromISOString,
    userResponse: TUserResponse,
    accountType: TAccountType,
  }),
  partial({
    invoiceUrl: string,
    totalPrice: number,
    holdAmount: number,
    expectedEndOfUsage: DateFromISOString,
    usageTimeInMinutes: number,
    finishedAt: DateFromISOString,
    startedAt: DateFromISOString,
  }),
])

export const TRentalsArray = array(TRental)
