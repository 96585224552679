export const createBody = (
  body: Record<string, any> | undefined,
  type: 'file' | 'json',
): FormData | string | undefined => {
  if (body === undefined) {
    return undefined
  }

  if (type === 'json') {
    return JSON.stringify(body)
  }

  const formData = new FormData()

  for (const [key, value] of Object.entries(body)) {
    if (Array.isArray(value)) {
      for (const file of value) {
        formData.append(key, file)
      }
    } else {
      formData.append(key, value)
    }
  }

  return formData
}
