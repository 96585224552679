import { number, string, type } from 'io-ts'
import { optionFromNullable } from 'io-ts-types'

export const TAddress = type({
  longitude: number,
  latitude: number,
  city: optionFromNullable(string),
  street: optionFromNullable(string),
  number: optionFromNullable(string),
})
