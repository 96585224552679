import { GetDiscountInput, GetDiscountListInput } from 'api/auth/discount'
import {
  GetSubscriptionInput,
  GetSubscriptionListInput,
} from 'api/operation/subscription/subscription.api'
import { buildModuleCacheKey } from 'lib/request'

import { GetUsersStatisticsInput } from './analytic'
import {
  GetBusinessAccountInput,
  GetBusinessAccountsListInput,
} from './auth/business'
import {
  GetBookingListInput,
  GetBusinessMembersWithUpcomingBookingsAndRentalInput,
  GetFeedbacksListInput,
  GetRentalListInput,
  GetSubscriberListInput,
  GetUserRentalsInput,
} from './operation'
import {
  GetBoothInput,
  GetBoothListInput,
  GetBoothsWithScheduleInput,
  GetFeaturesListInput,
  GetLocationInput,
  GetLocationsListInput,
  GetPricingInput,
  GetPricingsListInput,
} from './stuff'

/**
 * `qk` stands for "query keys"
 */
export const qk = buildModuleCacheKey({
  operation: {
    feedback: {
      list: (input: GetFeedbacksListInput) => [input],
    },
    booking: {
      list: (input: GetBookingListInput) => [input],
    },
    rental: {
      list: (input: GetRentalListInput) => [input],
      userRentals: (input: GetUserRentalsInput) => [input],
    },
    user: {
      membersWithBookingsAndRental: (
        input: GetBusinessMembersWithUpcomingBookingsAndRentalInput,
      ) => [input],
      subscribers: (input: GetSubscriberListInput) => [input],
    },
    subscription: {
      list: (input: GetSubscriptionListInput) => [input],
      details: (input: GetSubscriptionInput) => [input],
    },
  },
  analytic: {
    statistics: {
      users: (input: GetUsersStatisticsInput) => [input],
    },
  },
  auth: {
    discountCode: {
      list: (input: GetDiscountListInput) => [input],
      details: (input: GetDiscountInput) => [input],
    },
    businessAccount: {
      list: (input: GetBusinessAccountsListInput) => [input],
      details: (input: GetBusinessAccountInput) => [input],
    },
  },
  stuff: {
    booth: {
      list: (input: GetBoothListInput) => [input],
      allPods: null,
      details: (input: GetBoothInput) => [input],
      detailsEdit: (input: GetBoothInput) => [input],
      boothsWithSchedule: (input: GetBoothsWithScheduleInput) => [input],
    },
    feature: {
      list: (input: GetFeaturesListInput) => [input],
    },
    location: {
      list: (input: GetLocationsListInput) => [input],
      details: (input: GetLocationInput) => [input],
    },
    pricing: {
      list: (input: GetPricingsListInput) => [input],
      details: (input: GetPricingInput) => [input],
    },
  },
})
