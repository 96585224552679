import { createSvgIcon } from '@mui/material'

export const IconUsers = createSvgIcon(
  <g>
    <path
      d="M12 7.91667C12 9.5275 10.7408 10.8333 9.1875 10.8333C7.6342 10.8333 6.375 9.5275 6.375 7.91667C6.375 6.30584 7.6342 5 9.1875 5C10.7408 5 12 6.30584 12 7.91667Z"
      fill="currentColor"
    />
    <path d="M5.25 13.1667L3 15.5V19H12V13.1667H5.25Z" fill="currentColor" />
    <path d="M21 15.5L18.75 13.1667H14.25V19H21V15.5Z" fill="currentColor" />
    <path
      d="M16.5 10.8333C17.7426 10.8333 18.75 9.78866 18.75 8.5C18.75 7.21134 17.7426 6.16667 16.5 6.16667C15.2574 6.16667 14.25 7.21134 14.25 8.5C14.25 9.78866 15.2574 10.8333 16.5 10.8333Z"
      fill="currentColor"
    />
  </g>,
  'IconUsers',
)
