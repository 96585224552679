import { createListQueryParams, PaginationInput, SortInput } from 'api/list'
import { BookingStatus, RentalStatus } from 'api/operation'
import { pipe } from 'fp-ts/function'
import { formatDateHyphens } from 'lib/format'
import { get, post } from 'lib/request'
import { decodeBlob } from 'lib/request/blob'

export type GetUsersStatisticsExportInput = {
  sort: SortInput
  pagination: PaginationInput
  body: Record<string, any>
}

export const exportUsersStatisics = async ({
  pagination,
  sort,
  body,
}: GetUsersStatisticsExportInput) => {
  const params = createListQueryParams({ pagination, sort })

  return pipe(
    await post('api/analytic/export/users/statistics', {
      query: params,
      body,
    }),
    decodeBlob,
  )
}

export type GetFeedbacksExportInput = {
  sort: SortInput
  pagination: PaginationInput
  dateFrom: Date
  dateTo: Date
}

export const exportFeedbacks = async ({
  pagination,
  sort,
  dateFrom,
  dateTo,
}: GetFeedbacksExportInput) => {
  const params = createListQueryParams({ pagination, sort })

  params.set('dateFrom', formatDateHyphens(dateFrom))
  params.set('dateTo', formatDateHyphens(dateTo))

  return pipe(
    await get('api/analytic/export/feedbacks', { query: params, type: 'file' }),
    decodeBlob,
  )
}

export type GetRentalsAndBookingsExportInput = {
  status: RentalStatus | BookingStatus
  sort: SortInput
  pagination: PaginationInput
  dateFrom: Date
  dateTo: Date
}

export const exportRentalsAndBookings = async ({
  status,
  pagination,
  sort,
  dateFrom,
  dateTo,
}: GetRentalsAndBookingsExportInput) => {
  const params = createListQueryParams({ pagination, sort })

  params.set('status', status)
  params.set('dateFrom', formatDateHyphens(dateFrom))
  params.set('dateTo', formatDateHyphens(dateTo))

  return pipe(
    await get('api/analytic/export/usages', { query: params, type: 'file' }),
    decodeBlob,
  )
}
