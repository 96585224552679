import { literal, TypeOf, union } from 'io-ts'

export const TUserRole = union([
  literal('ADMIN'),
  literal('USER'),
  literal('DRAFT_USER'),
])

export const TCurrency = union([literal('USD'), literal('EUR'), literal('AUD')])

export type Currency = TypeOf<typeof TCurrency>

export const TBoothStatus = union([
  literal('DRAFT'),
  literal('ACTIVE'),
  literal('INACTIVE'),
  literal('CLOSED'),
  literal('OCCUPIED'),
  literal('PUBLISHING'),
])

export type BoothStatus = TypeOf<typeof TBoothStatus>

export const boothStatusMapping: Record<BoothStatus, string> = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CLOSED: 'Closed',
  OCCUPIED: 'Active',
  PUBLISHING: 'Publishing',
}

export const TRentalStatus = union([
  literal('STARTED'),
  literal('FINISHED'),
  literal('FAILED'),
  literal('CANCELED'),
  literal('BOOKED'),
])

export const TImageSize = union([
  literal('WEB_IMAGE'),
  literal('MOBILE_IMAGE'),
  literal('THUMBNAIL'),
])

export const TDiscountStatus = union([
  literal('ACTIVE'),
  literal('DISABLED'),
  literal('EXPIRED'),
])

export type DiscountStatus = TypeOf<typeof TDiscountStatus>

export const TBusinessAccountStatus = union([
  literal('ACTIVE'),
  literal('INACTIVE'),
  literal('PENDING'),
  literal('INITIAL'),
])

export const TStaffMemberStatus = TBusinessAccountStatus

export type BusinessStatus = TypeOf<typeof TBusinessAccountStatus>

export const TAccountType = union([literal('PERSONAL'), literal('BUSINESS')])

export type AccountType = TypeOf<typeof TAccountType>

export const TSubscriptionStatus = union([
  literal('ACTIVE'),
  literal('CANCELLING'),
  literal('CANCELED'),
  literal('PAID'),
  literal('FREEZE'),
])

export const TSubscriberStatus = union([
  TSubscriptionStatus,
  literal('PENDING'),
  literal('CANCELED_BY_ADMIN'),
])

export type SubscriberStatus = TypeOf<typeof TSubscriberStatus>

export const TDayOfWeek = union([
  literal('MONDAY'),
  literal('TUESDAY'),
  literal('WEDNESDAY'),
  literal('THURSDAY'),
  literal('FRIDAY'),
  literal('SATURDAY'),
  literal('SUNDAY'),
])

export type DayOfWeek = TypeOf<typeof TDayOfWeek>

export const dayOfWeekMapping: Record<string, DayOfWeek> = {
  Monday: 'MONDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
  Thursday: 'THURSDAY',
  Friday: 'FRIDAY',
  Saturday: 'SATURDAY',
  Sunday: 'SUNDAY',
}

export const TPodWorkingType = union([
  literal('ALL_DAY'),
  literal('SPECIFIC_HOURS'),
  literal('DAY_OFF'),
])

export type PodWorkingType = TypeOf<typeof TPodWorkingType>

export type SubscriptionStatus = TypeOf<typeof TSubscriptionStatus>
