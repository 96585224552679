import { TSubscriberStatus } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  string,
  type,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, optionFromNullable, UUID } from 'io-ts-types'

export const TUserBookingsAndRental = type({
  userId: UUID,
  email: string,
  bookingExists: boolean,
  startedRentalExists: boolean,
})

export const TUserResponsesList = array(TUserBookingsAndRental)

export const TSubscriber = intersection([
  type({
    accountId: UUID,
    /* If it's a personal account with a subscribtion,
  accountId refers to userId, if it's a corporate subscriber, accountId equals businessId **/

    firstName: optionFromNullable(string),
    lastName: optionFromNullable(string),
    businessName: optionFromNullable(string),
    subscriptionId: UUID,
    subscriptionName: string,
    availableMinutes: number,
    chargedAt: DateFromISOString,
    status: TSubscriberStatus,
    nextSubscriptionName: optionFromNullable(string),
  }),
  partial({
    email: string,
  }),
])

export type Subscriber = TypeOf<typeof TSubscriber>
