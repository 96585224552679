import { createSvgIcon } from '@mui/material'

export const IconRentals = createSvgIcon(
  <g>
    <path
      d="M21 3H3V10.7143L6.16905 10.7143L8.74048 6.85714L11.641 6.85714L14.1027 14.2424L16.4548 10.7143L21 10.7143V3Z"
      fill="currentColor"
    />
    <path
      d="M21 13.2857L17.831 13.2857L15.2595 17.1429L12.359 17.1429L9.89728 9.75764L7.54524 13.2857H3V21H21V13.2857Z"
      fill="currentColor"
    />
  </g>,
  'IconRentals',
)
