import { drawerClasses, SwipeableDrawer } from '@mui/material'
import { Menu } from 'components/layout/AppLayout/Menu'

type Props = {
  open: boolean
  onOpen: () => void
  onClose: () => void
}

export const AppDrawer = (props: Props) => {
  return (
    <SwipeableDrawer
      variant="temporary"
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
      ModalProps={{ keepMounted: true }}
      disableBackdropTransition
      sx={{
        [`& .${drawerClasses.paper}`]: {
          width: 278,
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
        },
      }}
    >
      <Menu closeDrawer={props.onClose} />
    </SwipeableDrawer>
  )
}
