import { Route } from 'react-router-dom'
import { useTitle } from 'react-use'

import { LazyRoute } from './lazy-route'

const Title = ({ title }: { title: string }) => {
  useTitle(title)
  return null
}

export const createRoutes = (routes: Array<LazyRoute>) => {
  return routes.map(route => (
    <Route
      key={route.path.join(',')}
      exact={route.exact}
      path={route.path}
      render={props => (
        <>
          <Title title={route.title} />
          <route.component {...props.match.params} />
        </>
      )}
    />
  ))
}
