import { createSvgIcon } from '@mui/material'

export const IconLocations = createSvgIcon(
  <g>
    <path
      d="M14.2218 13.1645H9.70862C9.73242 14.6469 9.95074 16.1202 10.3582 17.5465L11.3593 21.0502C11.5596 21.0635 11.7616 21.0702 11.9652 21.0702C12.1688 21.0702 12.3708 21.0635 12.5711 21.0502L13.5722 17.5465C13.9797 16.1202 14.198 14.6469 14.2218 13.1645Z"
      fill="currentColor"
    />
    <path
      d="M15.0711 20.5222C18.1916 19.3799 20.5063 16.5658 20.9304 13.1645H16.4808C16.4569 14.8568 16.2092 16.5389 15.744 18.167L15.0711 20.5222Z"
      fill="currentColor"
    />
    <path
      d="M16.4808 10.9057L20.9304 10.9057C20.5063 7.50445 18.1916 4.69029 15.0711 3.54801L15.744 5.90323C16.2092 7.53133 16.4569 9.21343 16.4808 10.9057Z"
      fill="currentColor"
    />
    <path
      d="M12.5711 3.01999C12.3708 3.00673 12.1688 3 11.9652 3C11.7616 3 11.5596 3.00673 11.3593 3.01999L10.3582 6.52376C9.95073 7.95006 9.73242 9.42332 9.70862 10.9057L14.2218 10.9057C14.198 9.42332 13.9797 7.95006 13.5722 6.52376L12.5711 3.01999Z"
      fill="currentColor"
    />
    <path
      d="M8.18638 18.167L8.8593 20.5222C5.73882 19.3799 3.42413 16.5658 3 13.1645H7.44958C7.47356 14.8568 7.72121 16.5389 8.18638 18.167Z"
      fill="currentColor"
    />
    <path
      d="M7.44958 10.9057H3C3.42413 7.50444 5.73882 4.69029 8.8593 3.548L8.18638 5.90323C7.72121 7.53133 7.47356 9.21343 7.44958 10.9057Z"
      fill="currentColor"
    />
  </g>,
  'IconLocations',
)
