import { logout } from 'api'
import { useAuthContext } from 'app/auth'
import { useEffect } from 'react'
import { useMutation } from 'react-query'

export const Logout = () => {
  const { mutate } = useMutation(logout)

  const { removeToken } = useAuthContext()

  useEffect(() => {
    mutate(undefined, {
      onSuccess: () => {
        removeToken()
      },
    })
  }, [mutate, removeToken])

  return null
}
