import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { pipe } from 'fp-ts/function'
import { UUID } from 'io-ts-types'
import { formatDateHyphens } from 'lib/format'
import { get, patch } from 'lib/request'

import { TRentalsArray } from './rental.codecs'

export type RentalStatus = 'STARTED' | 'FINISHED'

export type GetRentalListInput = {
  sort: SortInput
  pagination: PaginationInput
  dateFrom: Date
  dateTo: Date
  status: RentalStatus
}

export const getAllRentals = async ({
  sort,
  pagination,
  dateFrom,
  dateTo,
  status,
}: GetRentalListInput) => {
  const params = createListQueryParams({ pagination, sort })

  params.set('dateFrom', formatDateHyphens(dateFrom))
  params.set('dateTo', formatDateHyphens(dateTo))
  params.set('status', status)

  return pipe(
    await get('api/operation/rentals', {
      query: params,
    }),
    decodeJsonWithTotal(TRentalsArray),
  )
}

export type GetUserRentalsInput = {
  userId: UUID
  search: URLSearchParams
}

export const getUserRentals = async ({
  userId,
  search,
}: GetUserRentalsInput) => {
  return pipe(
    await get('api/operation/users/:userId/rentals', {
      params: {
        userId,
      },
      query: search,
    }),
    decodeJsonWithTotal(TRentalsArray),
  )
}

type FinishRentalInput = {
  rentalId: UUID
}

export const finishRental = async ({ rentalId }: FinishRentalInput) => {
  await patch('api/operation/rentals/:rentalId/finish', {
    params: {
      rentalId,
    },
  })
}
