export const routes = {
  index: '/',
  login: '/login',
  logout: '/logout',
  forgotPassword: '/password/reset',
  recoveryPassword: '/password/recovery',
  emailConfirmation: '/email',
  emailResend: '/email/resend',

  features: '/features',
  newFeature: '/features/new',

  locations: '/locations',
  newLocation: '/locations/new',
  editLocation: '/locations/:locationId',

  pricing: '/pricing',
  newPricing: '/pricing/new',
  editPricing: '/pricing/:pricingId',

  pods: '/pods',
  newPod: '/pods/new',
  newPodCopy: '/pods/new/:copyPodId',
  editPod: '/pods/edit/:podId',
  podDetails: '/pods/view/:podId',
  assignPodWorkingHours: '/pods/working-hours/assign',
  assignPodDaysOff: '/pods/days-off/assign',

  bookings: '/bookings',
  canceledBookings: '/users/:userId/canceled',
  upcomingBookings: '/users/:userId/upcoming',
  pastBookings: '/users/:userId/past',

  users: '/users',
  rentals: '/rentals',
  usages: '/usages/:userId',
  feedbacks: '/feedbacks',

  discountCodes: '/discount-codes',
  newDiscount: '/discounts/new',
  editDiscount: '/discounts/edit/:discountId',

  businessAccounts: '/business-accounts',
  newBusinessAccount: '/business-accounts/new',
  editBusinessAccount: '/business-accounts/edit/:businessId',
  newStaffMember: '/business-accounts/newStaffMember/:businessId',
  newBusinessAdmin: '/business-accounts/newBusinessAdmin/:businessId',

  subscriptions: '/subscriptions',
  editSubscription: '/subscriptions/edit/:subscriptionId',
  subscribers: '/subscribers',
} as const
