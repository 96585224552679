import { createSvgIcon } from '@mui/material'

export const IconPricings = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V7.09195C10.3784 7.20864 9.80349 7.4339 9.32397 7.75355C8.60215 8.2347 8 9.00922 8 10C8 10.9908 8.60215 11.7653 9.32397 12.2465C9.80349 12.5661 10.3784 12.7914 11 12.908V14.5438L9.7601 14.0667C9.33162 13.9018 8.84593 14.0499 8.5823 14.4258C8.31867 14.8016 8.34485 15.3087 8.64577 15.6555C9.20847 16.3038 10.0597 16.7309 11 16.9076V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16.908C13.6216 16.7914 14.1965 16.5661 14.676 16.2465C15.3979 15.7653 16 14.9908 16 14C16 13.0092 15.3979 12.2347 14.676 11.7535C14.1965 11.4339 13.6216 11.2086 13 11.092V9.45623L14.2399 9.9333C14.6684 10.0982 15.1541 9.95009 15.4177 9.57422C15.6813 9.19836 15.6552 8.69127 15.3542 8.34454C14.7915 7.69618 13.9403 7.26911 13 7.09236V7ZM10.4333 9.41771C10.5881 9.31452 10.7793 9.22229 11 9.15091V10.8491C10.7793 10.7777 10.5881 10.6855 10.4333 10.5823C10.0694 10.3397 10 10.1142 10 10C10 9.88578 10.0694 9.6603 10.4333 9.41771ZM13 14.8491V13.1509C13.2207 13.2223 13.4119 13.3145 13.5667 13.4177C13.9306 13.6603 14 13.8858 14 14C14 14.1142 13.9306 14.3397 13.5667 14.5823C13.4119 14.6855 13.2207 14.7777 13 14.8491Z"
      fill="currentColor"
    />
  </g>,
  'IconPricings',
)
