import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { pipe } from 'fp-ts/function'
import { formatDateHyphens } from 'lib/format'
import { get } from 'lib/request'

import { TFeedbacksArray } from './feedback.codecs'

export type GetFeedbacksListInput = {
  sort: SortInput
  pagination: PaginationInput
  dateFrom: Date
  dateTo: Date
}

export const getFeedbacksList = async ({
  pagination,
  sort,
  dateFrom,
  dateTo,
}: GetFeedbacksListInput) => {
  const params = createListQueryParams({ pagination, sort })

  params.set('dateFrom', formatDateHyphens(dateFrom))
  params.set('dateTo', formatDateHyphens(dateTo))

  return pipe(
    await get('api/operation/rentals/feedbacks', {
      query: params,
    }),
    decodeJsonWithTotal(TFeedbacksArray),
  )
}
