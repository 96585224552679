import { outlinedInputClasses } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#215250',
      light: '#EDF1F1',
    },
    secondary: {
      main: '#CD7A47',
    },
    error: {
      main: '#BF2600',
      dark: '#98280C',
      contrastText: '#FFF',
    },
    success: {
      main: '#3E8D90',
      light: '#4A9691',
      contrastText: '#2C74DE',
    },
    info: {
      main: '#1E3C3C',
    },
    background: {
      default: '#FFF',
    },
    text: {
      primary: '#0B0B0B',
      secondary: '#808080',
    },
    dim: {
      main: '#E5E5E5',
      dark: '#4A4A4A',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  typography: {
    fontFamily: ['RNS Sanz', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'inherit',
    },
    h2: {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 'inherit',
    },
    h3: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 'inherit',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'inherit',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'inherit',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'inherit',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#FFF',
          // Keep old body typography
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '44px',
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        outlined: {
          borderRadius: '4px',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '2px',
            borderRadius: '4px',
            borderColor: '#E5E5E5',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          lineHeight: '24px',
          padding: '10px 12px',
          fontSize: '16px',
          minWidth: 'auto',
        },
        sizeMedium: {
          height: '44px',
        },
        sizeSmall: {
          height: '32px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'dim' },
          style: {
            border: '2px solid #E5E5E5',
            color: '#0B0B0B',

            '&:hover': {
              border: '2px solid #4A4A4A',
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0 !important',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },

  // Keep old breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})
