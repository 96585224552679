import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconEyeClosed = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8419 18.1734L19.9728 21.3044L21.6522 19.625L5.02721 3L3.34783 4.67938L6.13727 7.46882C4.11507 9.10624 3 10.9647 3 10.9647V13.3397C3 13.3397 6.5625 19.2772 12.5 19.2772C14.1359 19.2772 15.5914 18.8265 16.8419 18.1734ZM14.0359 15.3675L9.2847 10.6162C9.06213 11.0813 8.9375 11.6022 8.9375 12.1522C8.9375 14.1197 10.5325 15.7147 12.5 15.7147C13.05 15.7147 13.5709 15.5901 14.0359 15.3675Z"
        fill="#E5E5E5"
      />
      <path
        d="M22 13.3397C22 13.3397 21.6536 13.917 20.9969 14.7116L11.3832 5.09784C11.7454 5.0518 12.1178 5.02719 12.5 5.02719C18.4375 5.02719 22 10.9647 22 10.9647V13.3397Z"
        fill="#E5E5E5"
      />
    </SvgIcon>
  )
}
