import { intersection, number, partial, string, type, TypeOf } from 'io-ts'
import { optionFromNullable, UUID } from 'io-ts-types'

export const TSubscription = intersection([
  type({
    subscriptionId: UUID,
    subscriptionName: string,
    pricePerMonth: number,
    availableMinutes: number,
    pricePerQuarter: optionFromNullable(number),
  }),
  partial({
    membersQuantity: number,
  }),
])

export const T3dsPayment = partial({
  clientSecret: string,
  payload: string,
  replyTo: string,
})

export type Subscription = TypeOf<typeof TSubscription>
