import CloseIcon from '@mui/icons-material/Close'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { BoothItemDetails, getBoothsList, qk } from 'api'
import { env } from 'app/env'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const PodUnpausingIndicator = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [unpausingPods, setUnpausingPods] = useState<Array<BoothItemDetails>>(
    [],
  )

  const body = {
    clientId: env.REACT_APP_APPLICATION_ID,
  }

  const $allPods = useQuery(
    qk.stuff.booth.allPods.toKey(),
    () => getBoothsList({ body }),
    {
      refetchInterval: 10 * 1000,
    },
  )

  useEffect(() => {
    const publishingPods = $allPods.data?.rows.filter(
      pod => pod.status === 'PUBLISHING',
    )

    if (publishingPods && publishingPods.length > 0) {
      setUnpausingPods(publishingPods)
    }

    if (
      publishingPods &&
      publishingPods.length === 0 &&
      unpausingPods.length > 0
    ) {
      enqueueSnackbar(
        `The pod${unpausingPods.length > 1 ? 's are' : ' is'} active`,
        {
          variant: 'success',
        },
      )
      setUnpausingPods([])
    }
  }, [$allPods.data?.rows, enqueueSnackbar, unpausingPods.length])

  if (unpausingPods && unpausingPods.length > 0) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        width="312px"
        p={1.5}
        position="absolute"
        top={20}
        left={0}
        right={0}
        marginLeft="auto"
        marginRight="auto"
        border="1px solid #4CAAA4"
        borderRadius="4px"
        zIndex={10}
      >
        <Box display="flex">
          <CircularProgress size={21} />
          <Typography variant="body1" ml={1}>
            {`The pod${
              unpausingPods.length > 1 ? 's are' : ' is'
            } unpausing...`}
          </Typography>
        </Box>
        <Box height={1} display="flex" alignItems="center">
          <CloseIcon sx={{ color: '#8B8B8B', fontSize: '17px' }} />
        </Box>
      </Stack>
    )
  }

  return null
}
