import { UUID } from 'io-ts-types'
import { get, patch, post } from 'lib/request'

export const resendConfirmationEmail = async (input: { email: string }) => {
  return await post('api/auth/confirmations/register', {
    query: new URLSearchParams({
      email: input.email,
    }),
  })
}

export const confirmRegistration = async (input: {
  requestId: UUID
  hash: string
}) => {
  return await patch('api/auth/confirmations/register/:requestId', {
    params: { requestId: input.requestId },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export const finishPasswordRecovery = async (input: {
  requestId: UUID
  hash: string
  newPassword: string
}) => {
  return await patch('api/auth/confirmations/password/:requestId', {
    params: { requestId: input.requestId },
    query: new URLSearchParams({
      hash: input.hash,
    }),
    body: {
      newPassword: input.newPassword,
    },
  })
}

export const sendPasswordRecoveryEmail = async (input: {
  email: string
  clientId: string
}) => {
  return await post('api/auth/confirmations/password', {
    body: input,
  })
}

export const validateConfirmRequest = async (input: {
  requestId: UUID
  hash: string
}) => {
  return await get('api/auth/confirmations/:requestId', {
    params: { requestId: input.requestId },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}
