import { TUserResponse } from 'api/auth'
import { TBooth } from 'api/stuff'
import { array, intersection, number, partial, strict, string } from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TFeedback = intersection([
  strict({
    feedbackId: string,
    userResponse: TUserResponse,
    booth: TBooth,
    rate: number,
    createdAt: DateFromISOString,
  }),
  partial({
    comment: string,
  }),
])

export const TFeedbacksArray = array(TFeedback)
