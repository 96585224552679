import { array, string, type } from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

export const TLocationBooth = type({
  createdAt: DateFromISOString,
  locationId: string,
  locationName: string,
})

export const TLocation = type({
  createdAt: DateFromISOString,
  locationId: UUID,
  locationName: string,
  booths: array(
    type({
      boothId: UUID,
      name: string,
    }),
  ),
})

export const TLocationsList = array(TLocation)
