import { createSvgIcon } from '@mui/material'

export const IconMyBooths = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 3H21V8H19V5H5V8H3V3ZM5 19V16H3V21H21V16H19V19H5Z"
    fill="currentColor"
  />,
  'IconMyBooths',
)
