import { createSvgIcon } from '@mui/material'

export const IconFeedbacks = createSvgIcon(
  <g>
    <path
      d="M10.8621 4.2168H13.1341L14.5336 9.81489L20.2902 9.41601L20.9923 11.5768L16.1007 14.6377L18.2589 19.9893L16.4208 21.3247L11.9981 17.6184L7.57542 21.3247L5.73734 19.9893L7.89557 14.6377L3.00391 11.5768L3.70599 9.41601L9.46259 9.81489L10.8621 4.2168Z"
      fill="currentColor"
    />
  </g>,
  'IconFeedbacks',
)
