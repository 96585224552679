import { createListQueryParams, PaginationInput } from 'api/list'
import { pipe } from 'fp-ts/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post, put } from 'lib/request'

import { TLocationBooth, TLocationsList } from './location.codecs'

export type GetLocationInput = {
  locationId: UUID
}

export const getLocation = async (input: GetLocationInput) => {
  return pipe(
    await get('api/stuff/locations/:locationId', {
      params: {
        locationId: input.locationId,
      },
    }),
    decodeJson(TLocationBooth),
  )
}

export type GetLocationsListInput = {
  search?: string
  pagination?: PaginationInput
}

export const getLocationsList = async (input: GetLocationsListInput) => {
  const query = createListQueryParams({ pagination: input.pagination })

  if (input.search !== undefined) {
    query.set('search', input.search)
  }

  return pipe(
    await get('api/stuff/locations', {
      query,
    }),
    decodeJson(TLocationsList),
  )
}

export const updateLocation = async (input: {
  locationId: UUID
  locationName: string
}) => {
  return await put('api/stuff/locations/:locationId', {
    params: {
      locationId: input.locationId,
    },
    body: {
      locationName: input.locationName,
    },
  })
}

export const deleteLocation = async (input: { locationId: UUID }) => {
  return await del('api/stuff/locations/:locationId', {
    params: {
      locationId: input.locationId,
    },
  })
}

export const createLocation = async (input: { locationName: string }) => {
  return await post('api/stuff/locations', {
    body: {
      locationName: input.locationName,
    },
  })
}

export const addLocationToBooths = async (input: {
  locationId: UUID
  boothIds: Array<UUID>
}) => {
  return await patch('api/stuff/locations/:locationId', {
    params: {
      locationId: input.locationId,
    },
    body: {
      boothIds: input.boothIds,
    },
  })
}
