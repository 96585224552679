import { createSvgIcon } from '@mui/material'

export const IconFeatures = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM13.869 8.66412C13.7846 8.67303 13.7037 8.62773 13.6673 8.55109L12.1624 5.38523C12.0896 5.23216 11.8714 5.23331 11.8002 5.38715L10.3316 8.56292C10.2958 8.6402 10.2151 8.68638 10.1304 8.678L6.46736 8.31572C6.29194 8.29837 6.18149 8.49999 6.29055 8.63848L8.44746 11.3777C8.5041 11.4496 8.50466 11.5508 8.44882 11.6234L6.31233 14.3996C6.20508 14.5389 6.31697 14.7389 6.49185 14.7204L10.131 14.3359C10.2154 14.327 10.2963 14.3723 10.3327 14.4489L11.8376 17.6148C11.9104 17.7678 12.1286 17.7667 12.1998 17.6129L13.6684 14.4371C13.7042 14.3598 13.7849 14.3136 13.8696 14.322L17.5326 14.6843C17.7081 14.7016 17.8185 14.5 17.7095 14.3615L15.5525 11.6223C15.4959 11.5504 15.4953 11.4492 15.5512 11.3766L17.6877 8.60042C17.7949 8.46106 17.683 8.26107 17.5082 8.27955L13.869 8.66412Z"
      fill="currentColor"
    />
  </g>,
  'IconFeatures',
)
