import { TBusinessAccountStatus } from 'api/unions'
import { array, number, string, type, TypeOf } from 'io-ts'
import { DateFromISOString, optionFromNullable, UUID } from 'io-ts-types'

export const TBusinessAccount = type({
  businessId: UUID,
  businessName: string,
  businessEmail: string,
  staffCount: optionFromNullable(number),
  status: TBusinessAccountStatus,
  createdAt: optionFromNullable(DateFromISOString),
  firstName: optionFromNullable(string),
  lastName: optionFromNullable(string),
  address: optionFromNullable(string),
  phoneNumber: optionFromNullable(string),
  removedAt: optionFromNullable(DateFromISOString),
  updatedAt: optionFromNullable(DateFromISOString),
  subscriptionName: optionFromNullable(string),
  subscriptionId: optionFromNullable(UUID),
  subscriptionMinutes: optionFromNullable(number),
})

export type BusinessAccount = TypeOf<typeof TBusinessAccount>

export const TBusinessAccountsList = array(TBusinessAccount)
