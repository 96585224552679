import { createListQueryParams, PaginationInput } from 'api/list'
import { pipe } from 'fp-ts/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post } from 'lib/request'

import { TFeaturesList } from './feature.codecs'

export type GetFeaturesListInput = {
  search?: string
  pagination?: PaginationInput
}

export const getFeaturesList = async (input: GetFeaturesListInput) => {
  const query = createListQueryParams({ pagination: input.pagination })

  if (input.search !== undefined) {
    query.set('search', input.search)
  }

  return pipe(
    await get('api/stuff/features', {
      query,
    }),
    decodeJson(TFeaturesList),
  )
}

export const deleteFeature = async (input: { featureId: UUID }) => {
  return await del('api/stuff/features/:featureId', {
    params: {
      featureId: input.featureId,
    },
  })
}

// @TODO: poor typing
export const createFeature = async (input: { values: any }) => {
  return await post('api/stuff/features', {
    body: input.values,
  })
}

export const addFeatureToBooths = async (input: {
  featureId: UUID
  boothIds: Array<UUID>
}) => {
  return await patch('api/stuff/features/:featureId', {
    params: {
      featureId: input.featureId,
    },
    body: {
      boothIds: input.boothIds,
    },
  })
}
