import { createListQueryParams, PaginationInput } from 'api/list'
import { pipe } from 'fp-ts/function'
import { UUID } from 'io-ts-types'
import { decodeJson, del, get, patch, post, put } from 'lib/request'

import { TPricing, TPricingsList } from './pricing.codecs'

export type GetPricingsListInput = {
  search?: string
  pagination?: PaginationInput
}

export const getPricingsList = async (input: GetPricingsListInput) => {
  const query = createListQueryParams({ pagination: input.pagination })

  if (input.search !== undefined) {
    query.set('search', input.search)
  }

  return pipe(
    await get('api/stuff/pricings', {
      query,
    }),
    decodeJson(TPricingsList),
  )
}

export type GetPricingInput = {
  pricingId: UUID
}

export const getPricing = async (input: GetPricingInput) => {
  return pipe(
    await get('api/stuff/pricings/:pricingId', {
      params: {
        pricingId: input.pricingId,
      },
    }),
    decodeJson(TPricing),
  )
}

export const addPricingToBooths = async (input: {
  pricingId: UUID
  boothIds: Array<UUID>
}) => {
  return await patch('api/stuff/pricings/:pricingId', {
    params: {
      pricingId: input.pricingId,
    },
    body: {
      boothIds: input.boothIds,
    },
  })
}

// @TODO: poor typing
export const updatePricing = async (input: {
  pricingId: UUID
  values: any
}) => {
  return await put('api/stuff/pricings/:pricingId', {
    params: {
      pricingId: input.pricingId,
    },
    body: input.values,
  })
}

export const deletePricing = async (input: { pricingId: UUID }) => {
  return await del('api/stuff/pricings/:pricingId', {
    params: {
      pricingId: input.pricingId,
    },
  })
}

// @TODO: poor typing
export const createPricing = async (input: { values: any }) => {
  return await post('api/stuff/pricings', {
    body: input.values,
  })
}
