import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconCalendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.0714 3H6.42857V5.4H4V9H21V5.4H18.5714V3H14.9286V5.4H10.0714V3Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path d="M21 11.4H4V21H21V11.4Z" fill="currentColor" fillOpacity="0.7" />
    </SvgIcon>
  )
}
