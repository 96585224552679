import { createSvgIcon } from '@mui/material'

export const IconBookings = createSvgIcon(
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42857 3H10.0714V5.4H14.9286V3H18.5714V5.4H21V9H4V5.4H6.42857V3ZM4 11.4H21V21H4V11.4ZM15.3679 19L20 14.4868L18.4741 13L14.6512 16.7247L13.5259 15.6283L12 17.1151L13.9345 19H15.3679Z"
      fill="currentColor"
    />
  </g>,
  'IconBookings',
)
