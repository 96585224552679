import { TCurrency, TStaffMemberStatus, TUserRole } from 'api/unions'
import {
  array,
  boolean,
  intersection,
  number,
  partial,
  strict,
  string,
  TypeOf,
} from 'io-ts'
import { DateFromISOString, UUID } from 'io-ts-types'

const TDebt = strict({
  amount: number,
  currency: TCurrency,
})

export const TUser = intersection([
  strict({
    userId: UUID,
    registeredAt: DateFromISOString,
    totalUsages: number,
    upcomingBookings: number,
    pastBookings: number,
    cancelledBookings: number,
    totalAudPayments: number,
    totalEurPayments: number,
    totalUsdPayments: number,
    active: boolean,
    role: TUserRole,
  }),
  partial({
    deactivatedAt: DateFromISOString,
    email: string,
    firstName: string,
    lastName: string,
    debtResponse: TDebt,
    totalUsageDuration: number,
    businessName: string,
    businessStatus: TStaffMemberStatus,
    subscriptionName: string,
  }),
])

export type User = TypeOf<typeof TUser>

export const TUsersStatistics = intersection([
  strict({
    notRemovedUsersCount: number,
    userStatisticResponses: array(TUser),
  }),
  partial({
    totalUsages: number,
    totalUpcomingBookings: number,
    totalCanceledBookings: number,
    totalPastBookings: number,
    totalUsdAmount: number,
    totalAudAmount: number,
    totalEurAmount: number,
    totalUsdDebt: number,
    totalEurDebt: number,
    totalAudDebt: number,
  }),
])
