import {
  createListQueryParams,
  decodeJsonWithTotal,
  PaginationInput,
  SortInput,
} from 'api/list'
import { pipe } from 'fp-ts/function'
import { post } from 'lib/request'

import { TUsersStatistics } from './analytic.codecs'

export type GetUsersStatisticsInput = {
  sort: SortInput
  pagination: PaginationInput
  body: Record<string, any>
}

export const getUsersStatistics = async ({
  pagination,
  sort,
  body,
}: GetUsersStatisticsInput) => {
  const params = createListQueryParams({ pagination, sort })

  return pipe(
    await post('api/analytic/users', { query: params, body }),
    decodeJsonWithTotal(TUsersStatistics),
  )
}
