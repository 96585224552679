import { requestError } from './error'

export const decodeBlob = async (response: Response): Promise<Blob> => {
  try {
    return await response.blob()
  } catch (error) {
    if (error instanceof Error) {
      throw requestError({
        error,
        response,
        type: 'parse_blob',
      })
    }

    // This shouldn't happen
    throw error
  }
}
