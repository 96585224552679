import { SvgIcon, SvgIconProps } from '@mui/material'

export const IconDiscountCodes = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_5556_1646)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69583 5.65595L3.42899 11.046L14.3158 21.9327L22.801 13.4474L11.9143 2.56068L6.52426 2.82752L5.11004 4.24173L3.69583 5.65595ZM10.7669 9.89859C11.5479 9.11754 11.5479 7.85121 10.7669 7.07016C9.98585 6.28911 8.71952 6.28911 7.93847 7.07016C7.15742 7.85121 7.15742 9.11754 7.93847 9.89859C8.71952 10.6796 9.98585 10.6796 10.7669 9.89859Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5556_1646">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
