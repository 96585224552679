import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { routes } from 'app/routes'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FallbackProps } from 'react-error-boundary'
import { useHistory } from 'react-router-dom'

export const RuntimeErrorDialog = (props: FallbackProps) => {
  const history = useHistory()

  return (
    <Dialog open>
      <DialogTitle>Something went wrong 🐞</DialogTitle>
      <DialogContent>
        <DialogContentText>
          It may be a server error or a client-side bug. <br />
          Please report the problem to the development team.
          <Typography color="error">
            <pre>{JSON.stringify(props.error)}</pre>
          </Typography>
          <CopyToClipboard text={JSON.stringify(props.error)}>
            <Button variant="outlined">Copy error to clipboard</Button>
          </CopyToClipboard>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            history.go(0)
          }}
        >
          Refresh this page
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.replace(routes.index)
            props.resetErrorBoundary()
          }}
        >
          Go to main page
        </Button>
      </DialogActions>
    </Dialog>
  )
}
